(function ($) {

  // Duplicating orbit heading, but hiding in desktop view via CSS
  var orbitContainer = $('.panel--home-page .large-4 .orbit').clone();
  $('.panel--home-page .large-8 .pane-events-solr-events-featured').prepend(orbitContainer);

  // Initialize foundation.
  $(document).foundation();

  // START - Extending jQuery functions.
  $.fn.extend({
    deBounce: function debounce(func, wait, immediate) {
      // Delay function calls.
      var timeout;
      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  });
  // END - Extending jQuery functions.

    // Setting dynamic row heights on News/Events Page
    var windowWidth = $(window).width();
    if(windowWidth > 1023){
        // Get an array of all element heights
        var elementHeights = $('.page-news-events .pane-events-solr-events-pane .views-row, .page-news-events .pane-news-solr-pane-news .views-row').map(function() {
            return $(this).height();
        }).get();
        // Math.max takes a variable number of arguments
        // `apply` is equivalent to passing each height as an argument
        var minHeight = Math.max.apply(null, elementHeights);
        // Set each height to the min height
        jQuery('.page-news-events .pane-events-solr-events-pane .views-row, .page-news-events .pane-news-solr-pane-news .views-row').css('min-height',minHeight);
        // Set fixed height on last row to fix button alignment
        jQuery('.page-news-events .pane-events-solr-events-pane .views-row-last, .page-news-events .pane-news-solr-pane-news .views-row-last').height(minHeight);

    }

    //Hiding media content if section is empty
    if ($('.row.related-media .field-content').is(':empty')){$('.row.related-media').remove();}

    //Hiding link section if empty
    if (jQuery('.field-name-field-link-collection__wrapper').text().trim().length < 1 ) {
       jQuery('.field-name-field-link-collection__wrapper').hide();
    }

  // START - Main menu behaviors.
  Drupal.behaviors.mainMenu = {
    attach: function (context, settings) {
      var menu_content = $('.l-header__menu__content');
      var animate_open = {translateX :['0%', '150%']};
      var animate_close = {translateX :['150%', '0%']};
      var windowWidth = $(window).width();
      $('.l-header__menu__main__link--menu', context).click(function() {
        $(this).toggleClass('open');
        if ($(this).hasClass('open')) {
          menu_content.addClass('show-menu');
          menu_content.removeClass('show-search');
          menu_content.velocity(animate_open);
          if(windowWidth < 641){
            $('body').addClass('sticky-nav');
            $('body, html').css('overflow','hidden');
            var mobileMenuHeight = $(window).innerHeight() - 93;
            $('.l-header__menu__content').css('max-height', mobileMenuHeight);
          }
          $('body').addClass('menu-open');
        }
        else {
          menu_content.velocity(animate_close);
          setTimeout(function(){
            menu_content.removeClass('show-menu show-search');
            $('body').removeClass('menu-open');
            $('body, html').css('overflow','');
          }, 500);
        }
        return false;
      });
      $('.l-header__menu__main__link--search', context).click(function() {
        $(this).toggleClass('open');
        if ($(this).hasClass('open')) {
          menu_content.addClass('show-search');
          menu_content.removeClass('show-menu');
          menu_content.velocity(animate_open);
        }
        else {
          menu_content.velocity(animate_close);
          setTimeout(function(){
            menu_content.removeClass('show-menu show-search');
            $('.l-header__menu__main__link--search').removeClass('open');
          }, 500);
        }
        return false;
      });

      $('.l-header__menu__content .close-button, body', context).click(function() {
          $('.l-header__menu__main__link--menu').removeClass('open');
          if (menu_content.hasClass('show-menu')) {
              menu_content.velocity(animate_close);
              setTimeout(function(){
                  menu_content.removeClass('show-menu');
                  $('body').removeClass('menu-open');
              }, 500);
          }
          if (menu_content.hasClass('show-search')) {
              menu_content.velocity(animate_close);
              setTimeout(function(){
                menu_content.removeClass('show-search');
                $('.l-header__menu__main__link--search').removeClass('open');
              }, 500);
          }
      });

      // Prevent closing animation when user clicks inside the menu
      menu_content.click(function(e){
            e.stopPropagation();
      });

      // Allow top level links in menu to toggle sub menu.
      $('.l-header__menu__content__link.dropdown > .dropdown-toggle', context).click(function() {
        $(this).closest('.l-header__menu__content__link').toggleClass('dropdown--open');
        $(this).siblings('.item-list').slideToggle();
      });

      // Allow top level links in menu to toggle sub menu.
      $('.l-header__menu__content__link.dropdown--open', context).each(function() {
        $(this).children('.item-list').css('display', 'block');
      });

      // Fixing breadcrumb/banner position on load
      $(window).load(function(){
          var height = $('.l-header').outerHeight();
          //var captionTop = $('.not-front.page-news-events .flexslider__caption').css('top');
          // Adjusting height to account for admin bar if user is logged in
          var admin_menu_height = $('#admin-menu').outerHeight();
          height = height - admin_menu_height;
          $('.l-top__bottom, .l-top__bottom--alt').css('padding-top', height);
      });

      // Fixing breadcrumb/banner position on resize
      $(window).resize(function() {
          var height = $('.l-header').outerHeight();
          var windowWidth = $(window).width();
          // Adjusting height to account for admin bar if user is logged in
          var admin_menu_height = $('#admin-menu').outerHeight();
          height = height - admin_menu_height;
          $('.l-top__bottom, .l-top__bottom--alt').css('padding-top', height);
      });

      // Sticky functions
      $(document).ready(function() {

        var alertBar = $('.l-help');
        var menuBar = $('.l-header');
        var body = $('body');
        var windowWidth = $(window).width();
        var menuHeight = menuBar.outerHeight();

        function resetTopValue() {
            if (body.hasClass('logged-in') && body.hasClass('sticky-nav')) {
                menuHeight = menuBar.outerHeight();
                if(windowWidth > 1015) {
                    menuHeight = menuBar.outerHeight() + 28;
                }
                alertBar.css('top', menuHeight);
            }
        }

        if (alertBar.length) {
        	body.addClass('has-alert');
        	menuHeight = menuBar.outerHeight();
        	alertBar.css('top', menuHeight);
        	$(window).resize(function() {
        		menuHeight = menuBar.outerHeight();
        		alertBar.css('top', menuHeight);
        	});
        	$(window).on("scroll", function() {
        		menuHeight = menuBar.outerHeight();
        		alertBar.css('top', menuHeight);
                resetTopValue();
        		if($(window).scrollTop() == 0){
        		    alertBar.css('top', menuHeight);
                }
        	});
        	$(".l-header").bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function() {
        		menuHeight = menuBar.outerHeight();
        		alertBar.css('top', menuHeight);
                resetTopValue();
        		setTimeout(function() {
        			menuHeight = menuBar.outerHeight();
        			alertBar.css('top', menuHeight);
                    resetTopValue();
        		}, 150);
        	});

        }

        function sticky(wait) {

          // Get header height to offset page
          // elements on function call.

          var height = $('.l-header').outerHeight();
          var library_header = $('.panel--library__header__inner');
          var admin_menu_height = $('#admin-menu').outerHeight();
          library_header.css('padding-top', height);
          height = height - admin_menu_height;
          $('.not-front:not(.page-news-events) .flexslider--top, .front .flexslider--top').css('padding-top', height);

          var runSticky = jQuery().deBounce(function () {

            // Sticky desktop
            $(window, context).scroll(function() {
              if ($(window).scrollTop()>= 5) {
                //library_header.css('padding-top', height);
                $('body').addClass('sticky-nav');
                //$('.l-top__bottom').css('padding-top', height);

              }
              else {
                library_header.css('padding-top', height);
                if(windowWidth > 640) {
                    $('body').removeClass('sticky-nav');
                }
                if((windowWidth < 640) && (!$('body').hasClass("menu-open"))){
                    $('body').removeClass('sticky-nav');
                }
                  //$('.flexslider--top').css('padding-top', 0);
              }
            });
          }, wait);
          runSticky(wait);
        }

        // Fire sticky function on page load.
        sticky(100);

        // Fire sticky function on window resize.
        $(window).resize(function() {
          sticky(500);
        });


      });

    }
  };
  // END - Main menu behaviors.

  // START - Simple Accordion Behaviors
  Drupal.behaviors.simpleAccordion = {
    attach: function (context, settings) {
      // Attach behavior to all accordion field collections...
      $('.field-name-field-accordion-content', context).each(function () {
        // ...and each field collection item.
        $('.field-collection-item-field-accordion-content', this).each(function () {
          // Bind clicking of the title to the display of the body content.
          var accordion_scope = this;
          $('.field-name-field-title', accordion_scope).click(function () {
            $(this).toggleClass('open');
            $(".field-name-field-body", accordion_scope).animate({
              height: "toggle"
            }, 500);
          });
        });
      });
    }
  };
  // END - Simple Accordion Behaviors

})(jQuery);
